import { Route, Routes } from '@remix-run/react';
import { useTitle } from 'react-use';

import bgAnalytics from '../../assets/img/bg-analytics.png';
import { AnalyticsSessionsListPage } from '../../components/Analytics/AnalyticsExperienceList';
import { OrgFeatureProvider, OrgRequired } from '../../components/Organization';
import {
  OrganizationAnalyticsOverall,
  OrganizationAnalyticsProgramDetails,
  OrganizationAnalyticsProgramRoundDetails,
  OrganizationAnalyticsPrograms,
} from '../../components/Organization/Details/Analytics';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { makeTitle } from '../../utils/common';

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  useTitle(makeTitle('Analytics'));

  return (
    <PublicHomeLayout>
      <div
        className='relative flex-1 overflow-y-auto scrollbar bg-local bg-no-repeat bg-cover'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgAnalytics})`,
        }}
      >
        <OrgRequired>
          {(organization) => (
            <OrgFeatureProvider org={organization} routePrefix=''>
              <Routes>
                <Route
                  index
                  element={
                    <OrganizationAnalyticsOverall
                      exportEnabled={getFeatureQueryParam('analytics-export')}
                      showPrograms={getFeatureQueryParam('programs-analytics')}
                      recentNSummaryDataPoints={getFeatureQueryParamNumber(
                        'analytics-summary-data-points'
                      )}
                    />
                  }
                />
                <Route
                  path='sessions'
                  element={
                    <AnalyticsSessionsListPage organization={organization} />
                  }
                />
                <Route path='programs'>
                  <Route index element={<OrganizationAnalyticsPrograms />} />
                  <Route
                    path=':programLinkId'
                    element={<OrganizationAnalyticsProgramDetails />}
                  />
                  <Route
                    path=':programLinkId/rounds/:roundId'
                    element={<OrganizationAnalyticsProgramRoundDetails />}
                  />
                </Route>
              </Routes>
            </OrgFeatureProvider>
          )}
        </OrgRequired>
      </div>
    </PublicHomeLayout>
  );
}
